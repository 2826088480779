import React, { useEffect } from 'react';
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO" 
import Logo from "../components/logo"
import Nav from "../components/nav"

const Template = ({ pageContext }) => {

  useEffect(() => {
    const nextButton = document.querySelector("nav .right-0")
    const prevButton = document.querySelector("nav .left-0")

    const keyBoardNavigation = (e) => {
      if(e.code === "ArrowRight" && nextButton) {
        nextButton.click()
      } else if(e.code === "ArrowLeft" && prevButton) {
        prevButton.click()
      }
    }

    window.addEventListener("keyup", keyBoardNavigation, {
      capture: true,
      once: true
    })
  }, [])

  return (
    <>
      <SEO />
      <Logo />
      <Nav />
      <main className="entry">
        <h2 dangerouslySetInnerHTML={{
          __html: pageContext.content,
        }} />
        <time className="time tracking-wide row-start-1" dateTime={pageContext.date}>
          {pageContext.date.replace(/-/g,"/")}
        </time>
        <nav>
          {pageContext.next && <Link className="grid items-center absolute bottom-10 sm:inset-y-1/2 right-0 sm:transform sm:-translate-y-1/2 h-20 w-10 sm:h-28 sm:w-14 text-white" to={`/${pageContext.next}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
            <span className="sr-only">{pageContext.next}</span>
          </Link>}
          {/* <Link className="grid items-center absolute bottom-10 sm:inset-y-1/2 transform -translate-x-1/2 inset-x-1/2 h-20 w-20 p-5" to={`/${pageContext.next}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
            </svg>
            <span className="sr-only">Read a random axiom & spur</span>
          </Link> */}
          {pageContext.prev && <Link className="grid items-center absolute bottom-10 sm:inset-y-1/2 left-0 sm:transform sm:-translate-y-1/2 h-20 w-10 sm:h-28 sm:w-14 text-white" to={`/${pageContext.prev}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            <span className="sr-only">{pageContext.prev}</span>
          </Link>}
        </nav>

        <div class="absolute bottom-3 left-1/2 transform -translate-x-1/2 text-sm flex gap-3 whitespace-no-wrap">
          <a href={`https://www.addtoany.com/share#url=https://axiomsandspurs.com/` + pageContext.date.replace(/-/g,"/") + `&amp;title=` + pageContext.content} target="_blank">Share</a> <span>/</span> <Link to="/about">About</Link>
        </div>
      </main>
    </>
  );
};

export const pageQuery = graphql`
    query BlogPostByPath {
        airtable {
            data {
              Date
              Content
            }
        }     
    }
`;

export default Template;